import React from 'react'
import { Grid, TextField, FormControlLabel, FormLabel, FormControl, Switch, Button } from '@material-ui/core'
import FileList from './../uploadMidia/FileList'
import Upload from './../uploadMidia/Upload'
import AddIcon from '@material-ui/icons/Add';
import Table from './../Table'
import SaveIcon from '@material-ui/icons/Save';

const cabecalhoTabela = [
  { id: 'produtoItem', numeric: false, disablePadding: true, label: 'Produto' },
  { id: 'quantidade', numeric: false, disablePadding: true, label: 'Quantidade' },
  { id: 'ativo', numeric: false, disablePadding: false, label: 'Ativo' }
]

const acoesTabela = ['editar', 'remove']

const FormProduto = ({ grupoProdutos, subGrupoProdutos, dados, updateField,
  updateFieldAtivo, uploadedFiles, handleUpload, handleDelete, listaProdutos,
  addItemCombo, updateFieldCombo, removeItemCombo, updateFieldItemAtivo, updateFieldEad,
  updateFieldDestaque, itemCombo, editarItem, removerItem, update, planosConta }) => (
  <React.Fragment>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Grupo Produtos"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idGrupoProduto"
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          <option value={dados.grupoProduto === "" ? "" : dados.idGrupoProduto}> {dados.grupoProduto === "" ? "Selecionar Grupo Produto" : dados.grupoProduto} </option>
          {grupoProdutos.map(grupoProduto => {
            return (
              <option key={grupoProduto._id} value={grupoProduto._id}> {grupoProduto.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Sub Grupo Produtos"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="idSubGrupoProduto"
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          <option value={dados.subGrupoProduto === "" ? "Selecionar Sub Grupo Produto" : dados.idsubGrupoProduto}> {dados.subGrupoProduto === "" ? "Selecionar Sub Grupo Produto" : dados.subGrupoProduto} </option>
          {subGrupoProdutos.map(subGrupoProduto => {
            return (
              <option key={subGrupoProduto._id} value={subGrupoProduto._id}> {subGrupoProduto.descricao} </option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={6} xs={12} sm={6}>
        <TextField className="input" label="Descrição" variant="outlined" size="small" name="descricao" value={dados.descricao} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Unidade de Medida"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="unidadeMedida"
          value={dados.unidadeMedida}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          {dados.unidadeMedida === "" &&
            <option value="">
              Selecionar Unidade Medida
					  </option>
          }
          <option value="UNI">
            Unidade
					</option>
          <option value="CAIXA">
            Caixa
					</option>
          <option value="KG">
            KG
					</option>
          <option value="GRAMAS">
            Gramas
					</option>
          <option value="LITROS">
            Litros
					</option>
          <option value="METROS">
            Metros
					</option>
          <option value="CENTIMETROS">
            Centimetro
					</option>
          <option value="COMBO">
            Combo
					</option>
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Visibilidade"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="visibilidade"
          value={dados.visibilidade}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          {dados.visibilidade === "" &&
            <option value="">
              Selecionar Visibilidade
					  </option>
          }
          <option value="FRANQUEADOR">
            Franqueador
					</option>
          <option value="UNIDADE">
            Unidade
					</option>
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField
          id="standard-select-currency"
          select
          label="Tipo"
          variant="outlined"
          className="input"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="tipo"
          value={dados.tipo}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{
            shrink: true
          }}
        >
          {dados.tipo === "" &&
            <option value="">
              Selecionar Tipo
					  </option>
          }
          <option value="PRODUTO">
            Produto
					</option>
          <option value="SERVICO">
            Serviço
					</option>
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={6}>
        <TextField className="input" label="Qtd. Embalagem" variant="outlined" size="small" name="embalagem" value={dados.embalagem} onChange={(e) => updateField(e)} InputLabelProps={{ shrink: true }} />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          label="Plano de Contas Receita*"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="plancontas_id"
          value={dados.plancontas_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {planosConta.filter(param => param.parametros.paramfin_caixa === "C").map((plano, key) => {
            return (
              <option key={key} value={plano.plancontas_id}>{plano.plancontas_codigo} - {plano.plancontas_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={3} xs={12} sm={4}>
        <TextField
          id="standard-select-currency"
          select
          variant="outlined"
          label="Plano de Contas Despesa*"
          fullWidth
          size="small"
          SelectProps={{
            native: true,
          }}
          name="plancontas_despesa_id"
          value={dados.plancontas_despesa_id}
          onChangeCapture={(e) => updateField(e)}
          InputLabelProps={{ shrink: true }}
        >
          <option value="">--Escolha--</option>
          {planosConta.filter(param => param.parametros.paramfin_caixa === "D").map((plano, key) => {
            return (
              <option key={key} value={plano.plancontas_id}>{plano.plancontas_codigo} - {plano.plancontas_descricao}</option>
            )
          })}
        </TextField>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Ead?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ead"
                color="primary"
                checked={dados.ead}
              />
            }
            size="small"
            label={dados.ead ? 'Sim' : 'Não'}
            name="ead"
            onChange={(e) => updateFieldEad(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={2} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Destaque ?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="destaque"
                color="primary"
                checked={dados.destaque}
              />
            }
            size="small"
            label={dados.destaque ? 'Sim' : 'Não'}
            name="destaque"
            onChange={(e) => updateFieldDestaque(e)}
          />
        </FormControl>
      </Grid>
      <Grid item md={1} xs={12} sm={6}>
        <FormControl component="fieldset" className="mag-top--8">
          <FormLabel component="legend">Ativo ?</FormLabel>
          <FormControlLabel className="mg_left_10"
            control={
              <Switch
                name="ativo"
                color="primary"
                checked={dados.ativo}
              />
            }
            size="small"
            label={dados.ativo ? 'Sim' : 'Não'}
            name="ativo"
            onChange={(e) => updateFieldAtivo(e)}
          />
        </FormControl>
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={12} xs={12} sm={12}>
        <TextField 
          label="Observacao" 
          variant="outlined" 
          size="small" 
          name="observacao" 
          value={dados.observacao}
          rows={5}
          multiline
          onChange={(e) => updateField(e)} 
          InputLabelProps={{ shrink: true }} 
        />
      </Grid>
    </Grid>
    <Grid container direction="row" spacing={1} className="mg_top_10">
      <Grid item md={10} xs={12} sm={9}>
        <Upload onUpload={handleUpload} />
      </Grid>
      {!!uploadedFiles.length && (
        <Grid item md={2} xs={12} sm={3}>
          <FileList files={uploadedFiles} onDelete={handleDelete} />
        </Grid>
      )}
    </Grid>

    {dados.unidadeMedida === 'COMBO' &&
      <div>
        <hr />
        <Grid container direction="row" spacing={1}>
          <Grid item md={12} xs={12} sm={6}>
            <h4>Produtos do Combo:</h4>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={6} xs={12} sm={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Produto"
              variant="outlined"
              className="input"
              fullWidth
              size="small"
              SelectProps={{
                native: true,
              }}
              name="produtoItem"
              value={itemCombo.produtoId}
              onChangeCapture={(e) => updateFieldCombo(e)}
              InputLabelProps={{ shrink: true }}
            >
              {itemCombo.produtoId === "" &&
                <option value="">
                  Selecionar Produto do Combo
                        </option>
              }
              {listaProdutos.map(produto => {
                return (
                  <option key={produto._id} value={produto._id}>
                    {produto.descricao}
                  </option>
                )
              })}
            </TextField>
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <TextField className="input" type="number" label="Quantidade" variant="outlined" size="small" name="quantidade" value={itemCombo.quantidade} onChange={(e) => updateFieldCombo(e)} />
          </Grid>
          <Grid item md={2} xs={12} sm={6}>
            <FormControlLabel className="mg_left_10"
              control={
                <Switch
                  name="ativo"
                  color="primary"
                  checked={itemCombo.ativo}
                />
              }
              label={itemCombo.ativo ? "Ativo" : "Inativo"}
              name="ativo"
              onChange={(e) => updateFieldItemAtivo(itemCombo.index)}
            />
          </Grid>
          <Grid item md={2} xs={12} sm={6} className="alignCenter">
            <Button fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={update ? <AddIcon /> : <SaveIcon />} onClick={e => addItemCombo(e)}>{update ? 'Alterar' : 'Adicionar'}</Button>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1} className="mg_top_10">
          <Grid item md={12} xs={12} sm={12}>
            <Table
              editar={e => editarItem(e)}
              remove={e => removerItem(e)}
              headCell={cabecalhoTabela}
              rows={dados.itensCombo.map(item => {
                return {
                  _id: item._id,
                  produtoItem: item.produtoItem,
                  quantidade: item.quantidade,
                  ativo: item.ativo,
                  index: item.index
                }
              })}
              acoes={acoesTabela} />
          </Grid>
        </Grid>
      </div>
    }

  </React.Fragment >
)

export default FormProduto