import React, { Component } from 'react'
import './Produto.scss'
import './../../main/ultil.scss'

import Main from '../../components/templates/Main'
import Nav from '../../components/templates/Nav'
import Footer from '../../components/templates/Footer'

import { Grid, Button } from '@material-ui/core'

import FormProduto from '../../components/forms/FormProduto'

import SaveIcon from '@material-ui/icons/Save';

import { uniqueId } from 'lodash'
import filesize from 'filesize'

import ModalErro from './../../components/modals/Erro'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

const initalState = {
  produto: {
    _id: "",
    descricao: "",
    ativo: true,
    grupoProduto: "",
    idGrupoProduto: "",
    subGrupoProduto: "",
    idSubGrupoProduto: "",
    unidadeMedida: "",
    visibilidade: "",
    observacao: "",
    embalagem: "",
    destaque: false,
    ead: false,
    itensCombo: [],
    tipo: "",
    plancontas_id: '',
    plancontas_despesa_id: '',
    estoque: {
      estoqueMinimo: "",
      estoqueAtual: "",
      valorVenda: "",
      precoCusto: ""
    }
  },
  itemCombo: {
    produtoId: "",
    produtoItem: "",
    quantidade: "",
    ativo: true
  },
  produtos: {
    list: []
  },
  grupoProdutos: {
    list: []
  },
  subGrupoProdutos: {
    list: []
  },
  subGrupoProdutosFiltrado: {
    list: []
  },
  uploadedFiles: [],
  deleteIcone: false,
  modalErro: false,
  erro: {
    titulo: "",
    descricao: ""
  },
  loading: true,
  loadingSalvar: false,
  trocaIcone: false,
  update: false,
  permissoes: {
    perm_alterar: false,
    perm_inserir: false,
    perm_visualizar: false,
    perm_deletar: false
  },
  planosConta: {
    list: []
  }
}

export default class CadastroProduto extends Component {

  state = { ...initalState }

  getToken() {
    const USER_TOKEN = localStorage.getItem('token')

    const config = {
      headers: {
        'Authorization': 'Bearer ' + USER_TOKEN,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }

    return config
  }

  async UNSAFE_componentWillMount() {

    this.setState({
      produto: {
        _id: "",
        descricao: "",
        ativo: true,
        grupoProduto: "",
        idGrupoProduto: "",
        subGrupoProduto: "",
        idSubGrupoProduto: "",
        unidadeMedida: "",
        visibilidade: "",
        observacao: "",
        embalagem: "",
        destaque: false,
        ead: false,
        itensCombo: [],
        tipo: "",
        plancontas_id: '',
        plancontas_despesa_id: '',
        estoque: {
          estoqueMinimo: "",
          estoqueAtual: "",
          valorVenda: "",
          precoCusto: ""
        }
      }
    })

    const { match: { params } } = this.props;

    const perfil = JSON.parse(localStorage.getItem('perfil'))

    let permissoes = perfil.permissoes.filter(param => param.tela.modulo.mod_slug === 'produto')[0]

    this.setState({
      permissoes
    })

    if (params.produtoId) {
      if (!permissoes.perm_alterar) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    } else {
      if (!permissoes.perm_inserir) {
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Você não tem permissão para acessa essa tela!"
        })

        this.backPage()
      }
    }

    if (params.produtoId) {
      try {

        const { data: produto } = await axios.get(`${process.env.REACT_APP_API_URL}/produtos/${params.produtoId}`, this.getToken())

        const itensCombo = []

        if (produto.itemCombo) {
          for (let i = 0; i < produto.itemCombo.length; i++) {
            itensCombo.push({
              _id: produto.itemCombo[i].itemcombo_id,
              produtoId: produto.itemCombo[i].itemcombo_idprodutoitem,
              produtoItem: produto.itemCombo[i].produtoitem.prod_descricao,
              quantidade: produto.itemCombo[i].itemcombo_quantidade,
              ativo: produto.itemCombo[i].itemcombo_ativo,
              index: i + 1
            })
          }
        }

        this.setState({
          produto: {
            _id: produto.prod_id,
            descricao: produto.prod_descricao,
            ativo: produto.prod_ativo,
            grupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
            idGrupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_id,
            subGrupoProduto: produto.subgrupoproduto.subgrupprod_descricao,
            idSubGrupoProduto: produto.subgrupoproduto.subgrupprod_id,
            unidadeMedida: produto.prod_unidademedida,
            visibilidade: produto.prod_visibilidade,
            observacao: produto.prod_observacao,
            embalagem: produto.prod_embalagem,
            destaque: produto.prod_destaque,
            ead: produto.prod_ead,
            tipo: produto.prod_tipo,
            idMidia: produto.prod_idmidia,
            tipo: produto.prod_tipo,
            plancontas_id: produto.produto_plano ? produto.produto_plano.prodplan_plancontas_id : '',
            plancontas_despesa_id: produto.produto_plano_despesa ? produto.produto_plano_despesa.prodplan_plancontas_id : '',
            itensCombo
          },
          uploadedFiles: [{
            id: produto.midia.mid_id,
            name: produto.midia.mid_nome,
            preview: produto.midia.mid_caminho,
            uploaded: true,
            url: produto.midia.mid_caminho
          }]
        })
      } catch (error) {
        console.log(error)
        this.handleOpenDialog({
          titulo: "Opps!",
          descricao: "Não foi possível buscar o produto, tente mais tarde"
        })

        this.backPage()
      }
    }

    await this.consultaPlanoContas()

    try {
      const { data: grupoProdutosAux } = await axios.get(`${process.env.REACT_APP_API_URL}/grupoProdutos`, this.getToken())

      const grupoProdutos = []

      grupoProdutosAux.forEach(grupoProduto => {
        grupoProdutos.push({
          _id: grupoProduto.grupprod_id,
          descricao: grupoProduto.grupprod_descricao,
          ativo: grupoProduto.grupprod_ativo
        })
      })

      const { data: subGrupoProdutosAux } = await axios.get(`${process.env.REACT_APP_API_URL}/subGrupoProdutos`, this.getToken())

      const subGrupoProdutos = []

      subGrupoProdutosAux.forEach(subGrupoProduto => {
        subGrupoProdutos.push({
          _id: subGrupoProduto.subgrupprod_id,
          idGrupoProduto: subGrupoProduto.grupoproduto.grupprod_id,
          grupoProduto: subGrupoProduto.grupoproduto.grupprod_descricao,
          descricao: subGrupoProduto.subgrupprod_descricao,
          ativo: subGrupoProduto.subgrupprod_ativo
        })
      })

      let filtro = {
        descricao: "",
        subGrupoProduto: "",
        grupoProduto: "",
        visibilidade: "",
        ativo: true
      }

      const { data: produtosAux } = await axios.post(`${process.env.REACT_APP_API_URL}/produtos/list`, {...filtro}, this.getToken())

      const produtos = []

      produtosAux.sort((a, b) => (a.prod_descricao > b.prod_descricao) ? 1 : ((b.prod_descricao > a.prod_descricao) ? -1 : 0)).filter(param => param.prod_ativo === true).forEach(produto => {
        produtos.push({
          _id: produto.prod_id,
          url: produto.midia.mid_caminho,
          descricao: produto.prod_descricao,
          unidadeMedida: produto.prod_unidademedida,
          subGrupoProduto: produto.subgrupoproduto.subgrupprod_descricao,
          grupoProduto: produto.subgrupoproduto.grupoproduto.grupprod_descricao,
          visibilidade: produto.prod_visibilidade,
          tipo: produto.prod_tipo,
          ativo: produto.prod_ativo
        })
      })

      this.setState({
        subGrupoProdutos: {
          list: subGrupoProdutos
        },
        grupoProdutos: {
          list: grupoProdutos
        },
        produtos: {
          list: produtos
        },
        loading: false
      })

    } catch (error) {
      console.log(error)
      this.handleOpenDialog({
        titulo: "Opps!",
        descricao: "Não foi possível buscar o grupo de produtos, tente mais tarde"
      })

      this.backPage()
    }
  }

  async consultaPlanoContas(){
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/planoContas`, this.getToken())

      this.setState({
        planosConta: {
          list: data.filter(param => param.plancontas_ativo === true && param.parametros)
          .sort((a, b) => (parseInt(a.plancontas_codigo) > parseInt(b.plancontas_codigo)) ? 1 : ((parseInt(b.plancontas_codigo) > parseInt(a.plancontas_codigo)) ? -1 : 0))
          .map(value => {
            return {
              plancontas_id: value.plancontas_id,
              plancontas_codigo: value.plancontas_codigo,
              plancontas_descricao: value.plancontas_descricao,
              plancontas_ativo: value.plancontas_ativo,
              plancontas_slug: value.plancontas_slug,
              parametros: value.parametros
            }
          })
        }
      })

    } catch ({ response }) {
      this.handleOpenDialog({
        titulo: 'Ops...',
        descricao: response.data.message
      })
      return
    }
  }

  updateField(event) {
    const produto = { ...this.state.produto }

    if (event.target.name === "idGrupoProduto") {
      const subGrupoProdutos = this.state.subGrupoProdutos.list.filter(u => u.idGrupoProduto === parseInt(event.target.value))

      this.setState({
        subGrupoProdutosFiltrado: {
          list: subGrupoProdutos
        }
      })
    }

    // if (event.target.name === "unidadeMedida") {
    //   let itensCombo = []
    //   if (event.target.value === "COMBO") {
    //     itensCombo.push({
    //       produtoId: "",
    //       produtoItem: "",
    //       quantidade: "",
    //       ativo: true,
    //       index: 1
    //     })
    //   } else {
    //     itensCombo = []
    //   }

    //   produto.itensCombo = itensCombo
    // }

    produto[event.target.name] = event.target.value
    this.setState({ produto })
  }

  updateFieldAtivo() {
    const produto = { ...this.state.produto }
    produto.ativo = !produto.ativo
    this.setState({ produto })
  }

  updateFieldDestaque() {
    const produto = { ...this.state.produto }
    produto.destaque = !produto.destaque
    this.setState({ produto })
  }

  updateFieldEad() {
    const produto = { ...this.state.produto }
    produto.ead = !produto.ead
    this.setState({ produto })
  }

  updateFieldItemAtivo() {
    const itemCombo = { ...this.state.itemCombo }
    itemCombo.ativo = !itemCombo.ativo
    this.setState({ itemCombo })
  }

  updateFieldCombo(event) {
    const itemCombo = this.state.itemCombo

    if (event.target.name === "produtoItem") {

      const produtos = this.state.produtos.list

      const indexProduto = produtos.findIndex(prod => prod._id === parseInt(event.target.value))
      itemCombo.produtoId = parseInt(event.target.value)
      itemCombo.produtoItem = produtos[indexProduto].descricao

    } else {

      itemCombo.quantidade = parseInt(event.target.value)
    }

    this.setState({ itemCombo })
  }

  handleUpload = files => {

    this.setState({
      uploadedFiles: []
    })

    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }))

    this.concatUploadedFiles(uploadedFiles)


  }

  concatUploadedFiles(uploadedFiles) {

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles),
      trocaIcone: true
    });

  }

  handleCloseErro() {
    this.setState({
      modalErro: !this.state.modalErro
    })
  }

  validateItemCombo() {
    const itemCombo = this.state.itemCombo

    if (!itemCombo.produtoId) return false
    if (!itemCombo.quantidade) return false

    return true
  }

  addItemCombo() {
    if (!this.validateItemCombo()) {
      this.handleOpenDialog({
        titulo: "Erro ao Adicionar!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const produto = this.state.produto
    const itemCombo = this.state.itemCombo

    const itens = produto.itensCombo

    if (itemCombo.index) {
      const index = produto.itensCombo.findIndex(param => param.index === itemCombo.index)

      itens[index] = {
        _id: itemCombo._id,
        produtoId: itemCombo.produtoId,
        produtoItem: itemCombo.produtoItem,
        quantidade: itemCombo.quantidade,
        ativo: itemCombo.ativo,
        index: itemCombo.index
      }
    } else {
      const index = itens.length + 1

      itens.push({
        produtoId: itemCombo.produtoId,
        produtoItem: itemCombo.produtoItem,
        quantidade: itemCombo.quantidade,
        ativo: itemCombo.ativo,
        index
      })
    }

    produto.itensCombo = itens

    this.setState({
      produto,
      itemCombo: {
        produtoId: "",
        produtoItem: "",
        quantidade: "",
        ativo: true
      },
      update: false
    })
  }

  editarItem(index) {
    const aux = this.state.produto.itensCombo.filter(param => param.index === index)

    this.setState({
      update: true,
      itemCombo: aux[0]
    })
  }

  removerItem(index) {
    const produto = this.state.produto

    let itens = produto.itensCombo

    itens.splice(index - 1, 1)

    const aux = []

    for (let i = 0; i < itens.length; i++) {
      aux.push({
        _id:itens[i]._id,
        produtoId: itens[i].produtoId,
        produtoItem: itens[i].produtoItem,
        quantidade: itens[i].quantidade,
        ativo: itens[i].ativo,
        index: i + 1
      })
    }

    itens = aux

    produto.itensCombo = itens

    this.setState({ produto })
  }

  removeItemCombo(index) {
    const produto = this.state.produto

    if (produto.itensCombo.length === 1) {
      const erro = {
        titulo: "Unico item do Combo!",
        descricao: "Não é possivel remover o unico item do Combo."
      }
      this.setState({
        modalErro: true,
        erro
      })
    } else if (produto.itensCombo.length > 1) {

      let itensCombo = produto.itensCombo

      itensCombo.splice(index - 1, 1)

      const aux = []

      for (let i = 0; i < itensCombo.length; i++) {
        aux.push({
          produtoId: itensCombo[i].produtoId,
          quantidade: itensCombo[i].quantidade,
          ativo: itensCombo[i].ativo,
          index: i + 1
        })

      }

      itensCombo = aux

      produto.itensCombo = itensCombo

      this.setState({ produto })

    }

  }

  backPage(timeout = 1000) {
    const self = this
    this.setState({
      produto: initalState.produto
    })
    setTimeout(() => {
      self.props.history.push("/produtos");
    }, timeout)
  }

  handleOpenDialog(error) {
    if (!error) error = {}

    this.setState({
      modalErro: true,
      erro: {
        titulo: error.titulo || 'Erro no Cadastro!',
        descricao: error.descricao || 'Erro inesperado, informe o suporte'
      }
    })
  }

  validateForm() {
    const { produto } = this.state

    if (!produto.descricao) return false
    if (!produto.idSubGrupoProduto) return false
    if (!produto.embalagem) return false
    if (!produto.tipo) return false
    if (!this.state.uploadedFiles.length === 0) return false

    if (produto.unidadeMedida === "COMBO") {
      produto.itensCombo.forEach(item => {

        if (!item.produtoId) return false
        if (!item.quantidade) return false
        if (!item.quantidade === 0) return false

        if (produto.itensCombo.length === 1) {
          if (item.quantidade === 1 || item.quantidade === 0) {
            return false
          }
        }

      });
    }

    return true
  }

  async salvar() {
    this.setState({ loadingSalvar: true })

    if (!this.validateForm()) {
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Preencha os campos obrigatorios (*)."
      })
      return
    }

    const { produto } = this.state

    if(produto.plancontas_id === ''){
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Por favor informar o plano de contas de receita."
      })
      return
    }

    if(produto.plancontas_despesa_id === ''){
      this.setState({ loadingSalvar: false })
      this.handleOpenDialog({
        titulo: "Erro no Cadastro!",
        descricao: "Por favor informar o plano de contas de despesa."
      })
      return
    }

    const uploadedFile = this.state.uploadedFiles[0]
    let auxItensCombos = []

    produto.itensCombo.forEach(item => {
      auxItensCombos.push({
        _id: item._id,
        produtoId: item.produtoId,
        quantidade: item.quantidade,
        ativo: item.ativo
      })
    });
    

    let data = new FormData();
    if (this.state.trocaIcone) {
      data.append("file", uploadedFile.file, uploadedFile.name);
    }
    if (produto._id !== "") {
      data.append("idMidia", produto.idMidia);
    }
    data.append("descricao", produto.descricao);
    data.append("unidadeMedida", produto.unidadeMedida);
    data.append("subGrupoProduto", produto.idSubGrupoProduto);
    data.append("visibilidade", produto.visibilidade);
    data.append("observacao", produto.observacao === '' ? 'N/A' : produto.observacao);
    data.append("embalagem", produto.embalagem);
    data.append("destaque", produto.destaque);
    data.append("ead", produto.ead);
    data.append("tipo", produto.tipo);
    data.append("ativo", produto.ativo);
    data.append("plancontas_id", parseInt(produto.plancontas_id));
    data.append("plancontas_despesa_id", parseInt(produto.plancontas_despesa_id));
    if (produto.unidadeMedida === "COMBO") {
      data.append("itensCombo", JSON.stringify(auxItensCombos));
    }

    try {

      if (produto._id !== "") {
        await axios.put(`${process.env.REACT_APP_API_URL}/produtos/${produto._id}`, data, this.getToken())
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/produtos`, data, this.getToken())
      }

      this.handleOpenDialog({
        titulo: 'Parabéns',
        descricao: 'Cadastro realizado com sucesso.'
      })
      this.backPage()

    } catch ({ response }) {
      this.setState({ loadingSalvar: false })
      if (response.status === 400 || response.status === 500) {
        this.handleOpenDialog({
          titulo: 'Ops...',
          descricao: response.data.message
        })
        return
      }

      this.handleOpenDialog({})
    }

  }

  render() {
    const { produto } = this.state

    return (
      <div className="app-menu-closed" id="app">
        <Main history={this.props.history}>
          {this.state.loading &&
            <div>
              <CircularProgress />
            </div>
          }
          {!this.state.loading &&
            <div>
              <Grid container spacing={1} direction="row" className="borderBottom">
                <Grid item md={11}>
                  <h2 className="titulo">Cadastro de Produtos</h2>
                </Grid>
                <Grid item md={1}>
                  {this.state.loadingSalvar &&
                    <div>
                      <CircularProgress />
                    </div>
                  }
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row">
                <Grid item md={12}>
                  <h3>Dados do Produto</h3>
                </Grid>
              </Grid>
              <FormProduto listaProdutos={this.state.produtos.list}
                grupoProdutos={this.state.grupoProdutos.list}
                subGrupoProdutos={this.state.subGrupoProdutosFiltrado.list}
                updateFieldAtivo={e => this.updateFieldAtivo(e)}
                updateFieldDestaque={e => this.updateFieldDestaque(e)}
                updateFieldEad={e => this.updateFieldEad(e)}
                updateField={e => this.updateField(e)}
                updateFieldCombo={e => this.updateFieldCombo(e)}
                dados={produto}
                uploadedFiles={this.state.uploadedFiles}
                handleUpload={this.handleUpload}
                addItemCombo={(e) => this.addItemCombo()}
                removeItemCombo={(index) => this.removeItemCombo(index)}
                updateFieldItemAtivo={(index) => this.updateFieldItemAtivo(index)}
                itemCombo={this.state.itemCombo}
                editarItem={e => this.editarItem(e)}
                removerItem={e => this.removerItem(e)}
                update={this.state.update} 
                planosConta={this.state.planosConta.list}
              />
              <hr />
              <Grid container direction="row" alignItems="flex-end" className="mg_top_10">
                <Grid item md={9}></Grid>
                <Grid item md={3}>
                  <Button disabled={this.state.loadingSalvar} fullWidth color="primary" variant="contained" className="btn_salvar" size="small" startIcon={<SaveIcon />} onClick={e => this.salvar(e)}>Salvar</Button>
                </Grid>
              </Grid>
            </div>
          }
          <ModalErro open={this.state.modalErro} titulo={this.state.erro.titulo} descricao={this.state.erro.descricao} handleClose={e => this.handleCloseErro(e)} />
        </Main>
        <Nav />
        <Footer history={this.props.history} />
      </div >
    )
  }
}